<template>
  <div class="prices mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Screen Control</h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text class="px-10 py-10">
          <v-row>
            <v-col cols="6">
              <h2 class="mb-10">OBE/RMS Infoscreen</h2>
              <h3>Status:</h3>
              <v-row>
                <v-col cols="auto" class="mt-5">
                  Aus
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="screenControlStatus"
                    :true-value="'on'"
                    :false-value="'off'"
                  ></v-switch>
                </v-col>
                <v-col class="mt-5">
                  Ein
                </v-col>
              </v-row>
              <h3>Quelle:</h3>
              <v-row>
                <v-col cols="auto" class="mt-5">
                  OBE/RMS Infoscreen (HDMI 1)
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="screenControlSource"
                    :true-value="'hdmi2'"
                    :false-value="'hdmi1'"
                  ></v-switch>
                </v-col>
                <v-col class="mt-5">
                  Signage Player (Spinetix) (HDMI 2)
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ScreenControl",

  computed: {
    ...mapState("app", ["configs"]),

    screenControlStatus: {
      get() {
        return this.configs.screen_control.status;
      },
      set(value) {
        this.$api.http
          .post(`crestron/send`, { command: "staircase_" + value })
          .then(response => {
            this.$toast.success("Status wurde geändert.");
          })
          .catch(() => {
            this.$toast.error("Der Status konnte nicht geändert werden.");
          });
      }
    },

    screenControlSource: {
      get() {
        return this.configs.screen_control.source;
      },
      set(value) {
        this.$api.http
          .post(`crestron/send`, { command: "staircase_" + value })
          .then(response => {
            this.$toast.success("Quelle wurde geändert.");
          })
          .catch(() => {
            this.$toast.error("Die Quelle konnte nicht geändert werden.");
          });
      }
    }
  }
};
</script>

<style scoped></style>
